import React from 'react';
import { Button } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import Spinner from '../util/Spinner';
import ConfirmAction from './ConfirmAction';
import AnimatedEllipsis from '../util/AnimatedEllipsis';

function ReleaseActions({ t, release, onSuspend, onResume, onUpdateRelease }) {
  const [action, setAction] = React.useState(null);
  const [busy, setBusy] = React.useState(false);
  const [variant, setVariant] = React.useState(null);
  const [actionKey, setActionKey] = React.useState(null);

  function handleConfirmOutcome(confirmed) {
    if (confirmed) {
      setBusy(true);
      action().then((updatedRelease) => {
        setBusy(false);
        setActionKey(null);
        onUpdateRelease && onUpdateRelease(updatedRelease);
        onResume && onResume();
      });
    } else {
      setActionKey(null);
      onResume && onResume();
    }
    setAction(null);
    setVariant(null);
  }

  function confirmAction(name, variant, action) {
    onSuspend && onSuspend();
    setAction(() => action);
    setVariant(variant);
    setActionKey(`release.actions.${name}`);
  }

  return (
    <div className="release-actions">
      <div className="row">
        <div className="progress-message col">
          {release.isBusy() && (
            <>
              {release.message}
              <AnimatedEllipsis fontSize="14pt" />
            </>
          )}
        </div>
        <div className="action col">
          {busy && <Spinner />}{' '}
          {release.canCancel() && (
            <Button
              className="cancel"
              variant="danger"
              disabled={busy}
              onClick={() =>
                confirmAction('cancel', 'danger', () => release.cancel())
              }
            >
              <i className="fas fa-stop" /> {t('release.labels.cancel')}
            </Button>
          )}
          {release.canProceed() && (
            <Button
              className="proceed"
              variant="primary"
              disabled={busy}
              onClick={() =>
                confirmAction(release.action, 'primary', () =>
                  release.proceed()
                )
              }
            >
              <i className="fas fa-play" />{' '}
              {t(`release.labels.${release.action}`)}
            </Button>
          )}
          {release.canRetry() && (
            <Button
              className="retry"
              variant="warning"
              disabled={busy}
              onClick={() =>
                confirmAction('retry', 'warning', () => release.retry())
              }
            >
              <i className="fas fa-recycle" /> {t('release.labels.retry')}
            </Button>
          )}
        </div>
      </div>
      {action !== null && (
        <ConfirmAction
          actionKey={actionKey}
          confirmVariant={variant}
          onOutcome={handleConfirmOutcome}
          translateContext={{ tag: release.tag }}
        />
      )}
    </div>
  );
}

export default withTranslator()(ReleaseActions);
