import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import Release from './Release';

function ReleaseCards({ t, releases, onSuspend, onResume, onUpdateRelease }) {
  const details = releases.map((release) => (
    <Release
      key={release.tag}
      release={release}
      onSuspend={onSuspend}
      onResume={onResume}
      onUpdateRelease={onUpdateRelease}
    />
  ));

  return (
    <>
      <Card className="heading">
        <Card.Header>
          <div className="row">
            <div className="col">{t('releases.labels.tag')}</div>
            <div className="col">{t('releases.labels.created')}</div>
            <div className="col">{t('releases.labels.lastModified')}</div>
            <div className="col">{t('releases.labels.stage')}</div>
            <div className="col-4">{t('releases.labels.status')}</div>
          </div>
        </Card.Header>
      </Card>
      {details}
    </>
  );
}

export default withTranslator()(ReleaseCards);
