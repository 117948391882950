import * as Names from './Names';
import Stage from './Stage';
export default class ReleaseModel {
  action;
  created;
  failed;
  href;
  lastModified;
  links;
  message;
  stage;
  stageProperties;
  status;
  tag;

  /**
   * Constructs a new instance
   * @param {AbstractClientAdapter} client the client adapter that was used to
   *    fetch `stackData`
   * @param {Object} releaseData the API resource representation of a release
   */
  constructor(client, releaseData) {
    this._client = client;
    Object.assign(this, releaseData);
  }

  /**
   * Refreshes this release.
   * @returns {Promise} a promise that resolves to a new ReleaseModel instance
   *    whose state represents the current API representation of the release
   */
  refresh() {
    return this._client
      .get(this.href)
      .then((releaseData) => new ReleaseModel(this._client, releaseData))
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this release can now accept a Cancel
   * request.
   * @returns {boolean} flag state
   */
  canCancel() {
    return typeof this.links[Names.CANCEL] !== 'undefined';
  }

  /**
   * Invokes this release's `cancel` link.
   * @returns {Promise} a promise that resolves to the data in the API
   *    response to the `cancel` request
   */
  cancel() {
    return this._client
      .post(this.links[Names.CANCEL])
      .then((releaseData) => new ReleaseModel(this._client, releaseData));
  }

  /**
   * Gets a flag indicating whether this release can now accept a Proceed
   * request.
   * @returns {boolean} flag state
   */
  canProceed() {
    return typeof this.links[Names.PROCEED] !== 'undefined';
  }

  /**
   * Invokes this release's `proceed` link.
   * @returns {Promise} a promise that resolves to the data in the API
   *    response to the `proceed` request
   */
  proceed() {
    return this._client
      .post(this.links[Names.PROCEED])
      .then((releaseData) => new ReleaseModel(this._client, releaseData));
  }

  /**
   * Gets a flag indicating whether this release can now accept a Retry
   * request.
   * @returns {boolean} flag state
   */
  canRetry() {
    return typeof this.links[Names.RETRY] !== 'undefined';
  }

  /**
   * Invokes this release's `retry` link.
   * @returns {Promise} a promise that resolves to the data in the API
   *    response to the `retry` request
   */
  retry() {
    return this._client
      .post(this.links[Names.RETRY])
      .then((releaseData) => new ReleaseModel(this._client, releaseData));
  }

  isFinal() {
    return this.stage === Stage.FINAL;
  }

  isBusy() {
    return !this.canProceed() && !this.canRetry() && !this.isFinal();
  }
}
