import React from 'react';
import { ApiContext } from 'summit-react';
import { withTranslator } from 'summit-react';
import SpinnerCard from '../util/SpinnerCard';

import ReleaseCards from './ReleaseCards';
import ReleasesEmpty from './ReleasesEmpty';

import './Releases.scss';

const REFRESH_DELAY = 5000;

function Releases(t) {
  const [releases, setReleases] = React.useState([]);
  const [releasesReady, setReleasesReady] = React.useState(false);
  const { api } = React.useContext(ApiContext);
  const timer = React.useRef(null);

  function refreshReleases() {
    api.fetchReleases().then((releases) => {
      // if we don't have a timer, we were suspended after we started the last
      // fetch, so we don't want to update state.
      if (timer.current) {
        setReleases(releases);
        timer.current = setTimeout(refreshReleases, REFRESH_DELAY);
      }
    });
  }

  function handleSuspend() {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
  }

  function handleResume() {
    timer.current = setTimeout(refreshReleases, REFRESH_DELAY);
  }

  function handleUpdateRelease(updatedRelease) {
    setReleases(
      releases.map((release) => {
        if (
          release.href === updatedRelease.href &&
          release.lastModified <= updatedRelease.lastModified
        ) {
          return updatedRelease;
        }
        return release;
      })
    );
  }

  React.useEffect(() => {
    api.fetchReleases().then((releases) => {
      setReleases(releases);
      setReleasesReady(true);
      timer.current = setTimeout(refreshReleases, REFRESH_DELAY);
    });
    return () => timer.current && clearTimeout(timer.current);
  }, [api]);

  return (
    <div className="releases container">
      {releasesReady && releases.length > 0 && (
        <ReleaseCards
          releases={releases}
          onSuspend={handleSuspend}
          onResume={handleResume}
          onUpdateRelease={handleUpdateRelease}
        />
      )}
      {releasesReady && releases.length === 0 && <ReleasesEmpty />}
      {!releasesReady && <SpinnerCard />}
    </div>
  );
}

export default withTranslator()(Releases);
