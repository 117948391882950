import React from 'react';
import { withTranslator } from 'summit-react';

import './StatusIcon.scss';

function ReleaseStatus({ t, release }) {
  return (
    <>
      <span className="status-icon">
        <StatusIcon release={release} />
      </span>
      <span className="status-label">
        {t(`release.statuses.${release.status}.${statusLabelKey(release)}`)}
      </span>
    </>
  );
}

function StatusIcon({ release }) {
  if (release.stage === 'FINAL') {
    if (release.status === 'CANCELED') {
      return <i className="fas fa-ban" />;
    } else {
      return <i className="green fas fa-check-circle" />;
    }
  }
  if (release.canProceed() || release.canRetry()) {
    if (release.failed) {
      return <i className="red fas fa-times-circle" />;
    } else {
      return <i className="hidden fas fa-check-circle" />;
    }
  } else {
    return <i className="fas fa-spin fa-circle-notch" />;
  }
}

function statusLabelKey(release) {
  if (release.canProceed() || release.canRetry()) {
    if (release.failed) {
      return 'failed';
    } else {
      return 'completed';
    }
  } else {
    return 'busy';
  }
}

export default withTranslator()(ReleaseStatus);
