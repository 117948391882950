import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator } from 'summit-react';

function ReleasesEmpty({ t }) {
  return (
    <Card>
      <Card.Header className="release empty">
        <p>{t('releases.labels.empty')}</p>
      </Card.Header>
    </Card>
  );
}

export default withTranslator()(ReleasesEmpty);
