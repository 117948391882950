import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import OauthClientFactory from 'vt-jwt-api-client';
import { QueryClient, QueryClientProvider } from 'react-query';

startup();

async function startup() {
  const config = await getConfig();
  const clientFactory = new OauthClientFactory({
    autoRedirect: true,
    redirectUri: window.location.origin,
    services: [
      {
        name: config.oauth.service,
        baseUrl: config.apiBasePath,
        endpoints: {
          authorization_endpoint: config.oauth.authEndpoint,
          token_endpoint: config.oauth.tokenEndpoint,
        },
      },
    ],
    clientId: config.oauth.clientId,
  });

  await clientFactory
    .bootstrap()
    .then(() => startReactApp(clientFactory, config))
    .catch(displayError);
}

async function getConfig() {
  var configResponse = await fetch('/config.json');
  if (Math.floor(configResponse.status / 100) !== 2)
    return new Promise((acc, rej) => {
      setTimeout(() => {
        console.log(configResponse);
        getConfig().then(acc).catch(rej);
      }, 1000);
    });
  else return configResponse.json();
}

function startReactApp(clientFactory, config) {
  const queryClient = new QueryClient();

  ReactDOM.render(
    /*Suspense fallback ui rendered while fetching i18n translation files*/
    <React.StrictMode>
      <Suspense fallback={'Loading...'}>
        <QueryClientProvider client={queryClient}>
          <App config={config} oauthClientFactory={clientFactory} />
        </QueryClientProvider>
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

function displayError(error) {
  if (error.type && error.type === 'ParentWindowStateNotFound') window.close();
  else if (error.authenticationRequired) return;

  ReactDOM.render(
    <LoginBootstrapErrorDisplay error={error} />,
    document.getElementById('root')
  );
}

function LoginBootstrapErrorDisplay({ error }) {
  return (
    <div id="auth-container" className="error">
      An error has occurred!
      <br />
      Message: {error.message}
      <pre>{error.stack}</pre>
    </div>
  );
}
