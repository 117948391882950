import React from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import Stage from '../../api/Stage';
import Chevron from '../util/Chevron';
import LocaleDateTime from '../util/LocaleDateTime';
import ReleaseActions from './ReleaseActions';

import ReleaseStatus from './ReleaseStatus';
import StageProperties from './StageProperties';

import './Release.scss';

function Release({ t, release, onSuspend, onResume, onUpdateRelease }) {
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandCollapse() {
    setExpanded(!expanded);
  }

  const stages = [Stage.INIT, Stage.PPRD, Stage.PROD, Stage.FINAL];
  return (
    <Card className="release">
      <Card.Header className="release-header">
        <div className="row">
          <div className="col">
            <Chevron.Control
              expanded={expanded}
              onClick={handleExpandCollapse}
            />
            {release.tag}
          </div>
          <div className="col">
            <LocaleDateTime isoInstant={release.created} />
          </div>
          <div className="col">
            <LocaleDateTime isoInstant={release.lastModified} />
          </div>
          <div className="col">{release.stage}</div>
          <div className="col-4">
            <ReleaseStatus release={release} />
          </div>
        </div>
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Body>
          {expanded && (
            <>
              {stages.map((stage) => (
                <StageProperties
                  key={stage}
                  stage={stage}
                  properties={release.stageProperties[stage]}
                />
              ))}
              <ReleaseActions
                release={release}
                onSuspend={onSuspend}
                onResume={onResume}
                onUpdateRelease={onUpdateRelease}
              />
            </>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
}

export default withTranslator()(Release);
