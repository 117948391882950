import * as Names from './Names';
import ReleaseModel from './ReleaseModel';

export default class API {
  /**
   * Constructs a new instance
   * @param {AbstractClientAdapter} client adapter that will be used to access
   *    the API
   */
  constructor(client) {
    this._client = client;
  }

  /**
   * Maps an array of release resources from the API to an array of ReleaseModel
   * objects, and optionally filters to a match a particular release.
   *
   * Since the API doesn't provide a way to filter the `releases` resource to
   * a single release, we do that here.
   *
   * @param {Object[]} releases array of API stack resources
   * @param {string} tag optional tag of a release to match
   * @returns array of ReleaseModel objects
   * @private
   */
  _mapReleases(releases, tag) {
    return releases
      .map((release) => new ReleaseModel(this._client, release))
      .filter((release) => !tag || (tag && release.tag === tag));
  }

  /**
   * Fetches the `releases` resource.
   * @param {string} [tag] ID of a stack to match in the response
   * @returns {Promise} a promise that resolves to an array of ReleaseModel
   *    objects
   */
  fetchReleases(tag) {
    if (this._root) {
      // if we've already fetched the root resource, reuse it to fetch
      // the `releases` resource
      return this._client
        .get(this._root[Names.RELEASES])
        .then((releases) => this._mapReleases(releases, tag))
        .catch(() => []);
    } else {
      // fetch and cache the root resource, then use it to retrieve the
      // `stacks` resource
      return this._client
        .get('/')
        .then((root) => {
          this._root = root;
          return this._client.get(root[Names.RELEASES]);
        })
        .then((releases) => this._mapReleases(releases, tag))
        .catch(() => []);
    }
  }
}
