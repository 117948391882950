import React from 'react';
import './MainRouter.scss';
import Switch from 'react-bootstrap/Switch';
import { Route, Routes } from 'react-router-dom';
import Releases from '../Releases/Releases';

export const RELEASES_PATH = '/';

function MainRouter() {
  return (
    <div className="main-router">
      <Switch>
        <Routes>
          <Route path={RELEASES_PATH} element={<Releases />} exact />
        </Routes>
      </Switch>
    </div>
  );
}

export default MainRouter;
