import React from 'react';

import './App.scss';
import './i18n';
import {
  ApiClient,
  ApiContext,
  AppFallbackUi,
  Body,
  ConfigContext,
  ErrorBoundary,
  Footer,
  GlobalErrorRelay,
  Header,
  LogoutModal,
  ReauthModal,
  withErrorBoundary,
  withTranslator,
} from 'summit-react';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './components/MainRouter/MainRouter';
import API from './api/API';
import ClientAdapter from './api/ClientAdapter';
import { SelfIssuedTokenClient } from './api/SelfIssuedTokenClient';

const FALLBACK_UI_COMPONENT = AppFallbackUi;

let authCb = null;
function App({ oauthClientFactory, config, t }) {
  const apiClient = React.useMemo(() => {
    const client =
      // In order to allow local development and testing with the actual
      // qa-infra API, you can generate a self-issued JWT signed using a
      // shared secret which is stored in our AWS account. See instructions
      // in the README.
      config.oauth.type === 'self-issued'
        ? new SelfIssuedTokenClient(config.apiBasePath)
        : oauthClientFactory.getServiceEndpoint(config.oauth.service);
    ApiClient.setConfig(config);
    ApiClient.setOauthClient(client);
    return client;
  }, [oauthClientFactory, config]);

  const [authNeeded, setAuthNeeded] = React.useState(false);

  oauthClientFactory.onAuthenticationRequired((cb) => {
    authCb = cb;
    setAuthNeeded(true);
  });

  const clientAdapter =
    config.oauth.type === 'self-issued'
      ? new ClientAdapter.Fetch(apiClient)
      : new ClientAdapter.Axios(apiClient);

  const apiContext = React.useMemo(
    () => ({
      api: new API(clientAdapter),
      client: apiClient,
      logout: oauthClientFactory.logout.bind(oauthClientFactory),
      onLogout: oauthClientFactory.onLogout.bind(oauthClientFactory),
    }),
    [apiClient, oauthClientFactory]
  );

  const onAuthClick = React.useCallback(() => {
    if (authCb === null) return;
    return authCb().then(() => {
      setAuthNeeded(false);
      authCb = null;
    });
  }, []);

  const [errorDetails, setErrorDetails] = React.useState(null);
  const onError = React.useCallback(
    (error) => setErrorDetails({ error }),
    [setErrorDetails]
  );

  if (!apiClient) return null;

  return (
    <ApiContext.Provider value={apiContext}>
      <ConfigContext.Provider value={config}>
        <GlobalErrorRelay onError={onError}>
          <ErrorBoundary
            FallbackUiComponent={FALLBACK_UI_COMPONENT}
            errorDetails={errorDetails}
            onErrorDetails={setErrorDetails}
          >
            <BrowserRouter>
              <Header LinksComponent={null}>{t('application.name')}</Header>
              <Body>
                <MainRouter />
              </Body>
              <Footer />
            </BrowserRouter>
            <ReauthModal authNeeded={authNeeded} onAuthClick={onAuthClick} />
            <LogoutModal onLogout={apiContext.onLogout} />
          </ErrorBoundary>
        </GlobalErrorRelay>
      </ConfigContext.Provider>
    </ApiContext.Provider>
  );
}

export default withErrorBoundary(withTranslator()(App), {
  FallbackUiComponent: FALLBACK_UI_COMPONENT,
});
