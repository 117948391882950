import React from 'react';
import { withTranslator } from 'summit-react';
import { Card, Collapse } from 'react-bootstrap';
import StagePropertyNames from '../../api/StagePropertyNames';
import Chevron from '../util/Chevron';

function StageProperties({ t, stage, properties }) {
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandCollapse() {
    setExpanded(!expanded);
  }

  if (Object.keys(properties).length === 0) return null;
  return (
    <Card className="stage-properties">
      <Card.Header>
        <Chevron.Control expanded={expanded} onClick={handleExpandCollapse} />
        {stage}
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Body>
          {properties[StagePropertyNames.ROUTER_ARN] && (
            <div className="row">
              <div className="prop-label col-3">
                {t('release.labels.stageProperties.routerArn')}
              </div>
              <div className="prop-value col-9">
                {properties[StagePropertyNames.ROUTER_ARN]}
              </div>
            </div>
          )}
          {properties[StagePropertyNames.SNAPSHOT_ID] && (
            <div className="row">
              <div className="prop-label col-3">
                {t('release.labels.stageProperties.snapshotId')}
              </div>
              <div className="prop-value col-9">
                {properties[StagePropertyNames.SNAPSHOT_ID]}
              </div>
            </div>
          )}
          {properties[StagePropertyNames.TASK_DEFINITION_ARN] && (
            <div className="row">
              <div className="prop-label col-3">
                {t('release.labels.stageProperties.taskDefinitionArn')}
              </div>
              <div className="prop-value col-9">
                {properties[StagePropertyNames.TASK_DEFINITION_ARN]}
              </div>
            </div>
          )}
          {properties[StagePropertyNames.DISTRIBUTION_ID] && (
            <div className="row">
              <div className="prop-label col-3">
                {t('release.labels.stageProperties.distributionId')}
              </div>
              <div className="prop-value col-9">
                {properties[StagePropertyNames.DISTRIBUTION_ID]}
              </div>
            </div>
          )}
          {properties[StagePropertyNames.INVALIDATION_ID] && (
            <div className="row">
              <div className="prop-label col-3">
                {t('release.labels.stageProperties.invalidationId')}
              </div>
              <div className="prop-value col-9">
                {properties[StagePropertyNames.INVALIDATION_ID]}
              </div>
            </div>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
}

export default withTranslator()(StageProperties);
